var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"continentForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-8 offset-xs-2 col-md-8 offset-md-2 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" ")])]),_c('div',{staticClass:"card-body"},[_c('ValidationProvider',{attrs:{"vid":"name","rules":"required","name":"The name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Name *","name":"name","fou":""},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","rules":"","name":"The english name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"english Name","name":"en_name","fou":""},model:{value:(_vm.formData.en_name),callback:function ($$v) {_vm.$set(_vm.formData, "en_name", $$v)},expression:"formData.en_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","rules":"","name":"The french name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"french Name","name":"fr_name","fou":""},model:{value:(_vm.formData.fr_name),callback:function ($$v) {_vm.$set(_vm.formData, "fr_name", $$v)},expression:"formData.fr_name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"name","rules":"","name":"The spanish name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"spanish Name","name":"sp_name","fou":""},model:{value:(_vm.formData.es_name),callback:function ($$v) {_vm.$set(_vm.formData, "es_name", $$v)},expression:"formData.es_name"}})]}}],null,true)})],1),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_vm._v("  "),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/continents/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }